<template>
  <v-dialog
    v-model="isOpen"
    width="380"
    persistent
    @keydown.esc="closeModal"
    eager
  >
    <v-card class="card-style">
      <v-toolbar flat>
        <v-toolbar-title class="title-header">
          {{ $t('Note.filter_note') }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon color="gray" @click="closeModal" right>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <notes-filter
        :modal-open="isOpen"
        @toModal="setFilter"
        @filters="setFilters"
        class="filter-notes"
        ref="filterNotes"
      />
      <v-card-actions class="distance-buttons">
        <v-btn class="text-none button-remove-and-close" @click="closeModal">
          <span class="text-cancel-and-remove">{{ $t('cancelar') }}</span>
        </v-btn>
        <v-spacer class="pl-2"></v-spacer>
        <v-btn tile class="text-none button-reset-filter" @click="resetFilters">
          <span class="text-button-reset-filter">
            {{ $t('clean_filter') }}</span
          >
          <v-icon class="ml-1" small> mdi-close </v-icon>
        </v-btn>
        <v-btn
          class="white--text text-none button-register-confirm"
          @click="searchNotes"
          :loading="filtering"
          :disabled="!filter.length"
        >
          <span v-if="!$vuetify.breakpoint.xs"> {{ $t('filter') }}</span
          ><v-icon class="icon-button" small>mdi-magnify</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NotesFilter from '@/modules/notes/components/NotesFilter.vue'

export default {
  name: 'ModalFilterNote',

  data() {
    return {
      filtering: false,
      isOpen: false,
      filter: [],
    }
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    NotesFilter,
  },

  watch: {
    dialog(newDialog) {
      this.isOpen = newDialog
    },
    isOpen(newIsOpen) {
      if (!newIsOpen) {
        this.closeModal()
      }
    },
  },

  methods: {
    setFilter(filterReceived) {
      this.filter = filterReceived
    },
    setFilters(filterReceived) {
      this.$emit('filters', filterReceived)
    },
    closeModal() {
      this.$emit('close')
    },
    resetFilters() {
      this.$refs.filterNotes.reset()
    },
    searchNotes() {
      this.filtering = true
      this.$refs.filterNotes.filterNotes()
      this.filtering = false
      this.closeModal()
    },
  },
}
</script>

<style scoped>
::v-deep .v-dialog {
  overflow-x: hidden;
}
::v-deep .filter {
  margin-left: 0%;
  max-width: 100%;
  padding-left: 28px;
}
::v-deep .category {
  margin-left: 0%;
  max-width: 100%;
  padding-left: 28px;
}
::v-deep .row-categories {
  margin-left: -106px;
  margin-right: 7px;
}

.card-style {
  border-radius: 8px;
}

.distance-buttons {
  padding: 10px 20px;
}

.title-header {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  color: #1a2b46;
}

.button-remove-and-close {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background: rgba(0, 0, 0, 0.4) !important;
}

.button-register-confirm {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
}

.icon-button {
  padding-top: 2px;
  padding-left: 8px;
}

.text-cancel-and-remove {
  color: #f8f9f8;
}

.button-reset-filter {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background: #fff !important;
}

.text-button-reset-filter {
  color: #1a2b46;
}

@media screen and (max-width: 960px) {
  ::v-deep .row-categories {
    margin-left: 9px;
  }
}

@media screen and (max-width: 600px) {
  .icon-button {
    padding-left: 0px;
  }
}
</style>
