<template>
  <div class="df-flex-l gallery">
    <div
      v-for="(image, index) in displayedImages"
      :key="index"
      @click="openModal(index)"
    >
      <v-img
        aspect-ratio="1"
        class="gallery__image"
        height="100"
        width="100"
        :alt="image.name.toLowerCase()"
        :src="image.storage_link"
      >
        <template #placeholder>
          <df-skeleton-loader height="100%" />
        </template>
        <div
          v-if="showMask && index === maxDisplayedImages - 1"
          class="gallery__image-mask"
        >
          + {{ remainingCount }}
        </div>
      </v-img>
    </div>

    <v-dialog v-model="dialog" hide-overlay :width="carouselWidth">
      <v-carousel
        v-if="images.length"
        class="gallery__modal"
        dark
        hide-delimiters
        :value="currentIndex"
        @change="updateCurrentIndex"
      >
        <v-carousel-item v-for="(image, jindex) in images" :key="jindex">
          <v-img
            class="modal__image"
            contain
            :aspect-ratio="getAspectRatio(image.storage_link)"
            :src="image.storage_link"
          />
        </v-carousel-item>
      </v-carousel>
    </v-dialog>
  </div>
</template>

<script>
import LayoutMixin from '@/components/LayoutMixin.vue'
import '@/lib/components/Loader/DfSkeletonLoader.js'

export default {
  name: 'Gallery',

  mixins: [LayoutMixin],

  props: {
    images: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      carouselWidth: 800,
      carouselHeight: null,
      currentIndex: 0,
      dialog: false,
      displayedImages: [],
      maxDisplayedImages: 7,
      remainingCount: 0,
      showMask: false,
    }
  },

  mounted() {
    if (this.isDSMobile) this.maxDisplayedImages = 2
    else if (this.isDSTablet) this.maxDisplayedImages = 4

    this.displayedImages = this.images.slice(0, this.maxDisplayedImages)
    if (this.images.length > this.maxDisplayedImages) {
      this.showMask = true
      this.remainingCount = this.images.length - this.maxDisplayedImages
    }

    window.addEventListener('resize', this.calculateCarouselHeight)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateCarouselHeight)
  },

  methods: {
    calculateCarouselHeight() {
      const aspectRatio = 16 / 9
      this.carouselHeight = this.carouselWidth / aspectRatio
    },
    getAspectRatio(imgSrc) {
      const img = new Image()
      img.src = imgSrc
      const width = img.width
      const height = img.height
      return width > height ? width / height : height / width
    },
    openModal(index) {
      this.currentIndex = index
      this.dialog = true
    },
    updateCurrentIndex(newIndex) {
      this.currentIndex = newIndex
    },
  },
}
</script>

<style lang="scss" scoped>
.gallery {
  .gallery__image {
    position: relative;
    border-radius: $border-radius-m !important;

    .gallery__image-mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include heading-medium;
      color: $color-text-white;
    }
  }
}
.gallery__modal {
  position: relative;
  background-color: $color-background-white;

  .modal__image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
