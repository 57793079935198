<template>
  <v-card class="card-style" data-id="note_list" outlined>
    <v-container class="pa-0">
      <df-loading v-if="loading" />
      <v-row v-if="shouldShowFilterReset" class="filter-text">
        <v-col cols="12" class="text-end">
          <v-btn
            class="clear-filter-button text-none"
            text
            @click="clearFilters"
          >
            <span v-if="!isMobile">{{ $t('clean_filter') }}</span>
            <v-icon class="icon-clear-filter ml-2" small> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="shouldShowNotes">
        <v-list
          subheader
          three-line
          v-for="(dayGroup, i) in notesGroupedByDay"
          :key="i"
        >
          <v-subheader class="note_day color-neutral-1 text-none px-1">
            {{ dayGroup.day }}
          </v-subheader>
          <v-list-item
            class="d-block d-sm-flex"
            v-for="note in dayGroup.notes"
            :key="note.id"
          >
            <v-container>
              <v-row no-gutters>
                <v-col cols="9" sm="12">
                  <v-row no-gutters>
                    <v-col cols="3" sm="1" class="text-left text-sm-center">
                      <span class="note_hour color-neutral-0">
                        {{ note.created_at }}
                      </span>
                    </v-col>
                    <v-col cols="9" sm="11" class="color-neutral-1">
                      <font-awesome-icon
                        class="personalized-icon"
                        :icon="iconCategory(note.category.name)"
                      />
                      <span
                        class="note_category text-uppercase color-secondary-light-1 px-1"
                      >
                        {{ $t('Note.category.' + note.category.name) }}
                      </span>
                      <span
                        class="note_plot px-1 text-uppercase"
                        v-if="hasPlot(note)"
                      >
                        /
                        <v-icon class="ml-2" small> mdi-vector-square </v-icon>
                        {{ note.plot.name }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pt-2">
                    <v-spacer class="d-none d-sm-block"></v-spacer>
                    <v-col cols="12" sm="8" md="9">
                      <span class="color-neutral-m-3 note_title">
                        {{ note.title }}
                      </span>
                    </v-col>
                    <v-col sm="3" md="2" class="d-none d-sm-block">
                      <v-btn
                        v-if="enabledFarm && hasPermission($p.note.edit)"
                        width="39"
                        height="31"
                        class="mx-1 color-neutral-1 no-shadow mr-2"
                        x-small
                        @click="openModalNote(note)"
                      >
                        <v-icon x-small>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="enabledFarm && hasPermission($p.note.delete)"
                        width="39"
                        height="31"
                        class="mx-1 color-neutral-1 no-shadow"
                        x-small
                        @click="openModalDeleteNote(note)"
                      >
                        <v-icon x-small>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pb-1">
                    <v-spacer class="d-none d-sm-block" />
                    <v-col
                      cols="12"
                      sm="11"
                      class="color-neutral-2 note_author"
                    >
                      <span>
                        <v-icon class="ma-1 ml-0" small>mdi-account</v-icon>
                        {{ $t('created_by') }}
                        {{ note.created_by.name || note.created_by.email }}
                      </span>
                      <span v-if="wasEdited(note)">
                        / {{ $t('edited_by') }} {{ note.edited_by.name }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-spacer class="d-none d-sm-block" />
                    <v-col
                      cols="12"
                      sm="11"
                      class="color-neutral-1 note_description text-justify"
                    >
                      {{ note.description }}
                    </v-col>
                  </v-row>
                  <v-row data-app="true">
                    <v-spacer class="d-none d-sm-block" />
                    <v-col cols="12" sm="11">
                      <template v-for="file in docsFiles(note.files)">
                        <v-btn
                          outlined
                          @click="openFile(file)"
                          :key="file.id"
                          class="mr-4"
                        >
                          <font-awesome-icon
                            class="paperclip-icon"
                            icon="paperclip"
                          />
                          <span class="pl-2 file-name pr-2">
                            {{ file.name }}
                          </span>
                          <font-awesome-icon
                            class="download-icon"
                            icon="download"
                          />
                        </v-btn>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row data-app="true" no-gutters>
                    <v-spacer class="d-none d-sm-block"></v-spacer>
                    <v-col cols="12" sm="11">
                      <gallery :images="imgFiles(note.files)" />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="3" class="flex-column d-sm-none align-self-center">
                  <v-btn
                    v-if="enabledFarm && hasPermission($p.note.edit)"
                    width="39"
                    height="31"
                    class="mx-4 color-neutral-1 no-shadow mb-2"
                    x-small
                    @click="openModalNote(note)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="enabledFarm && hasPermission($p.note.delete)"
                    width="39"
                    height="31"
                    class="mx-4 color-neutral-1 no-shadow"
                    x-small
                    @click="openModalDeleteNote(note)"
                  >
                    <v-icon x-small>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item>
        </v-list>
      </div>
      <div v-else-if="shouldShowNoNotes">
        <v-row justify="center">
          <v-col cols="12" md="5">
            <p class="text1">{{ $t('Note.no_notes') }}</p>
            <p class="text2">{{ $t('Note.add_first') }}</p>
            <p class="text3">{{ $t('Note.add_first_msg') }}</p>
            <v-btn
              v-if="enabledFarm && hasPermission($p.note.create)"
              class="white--text text-none my-4 button-new-note"
              @click="openModalNote"
            >
              {{ $t('Note.new_note') }}
            </v-btn>
          </v-col>
          <v-col cols="3" v-if="!isMobile">
            <v-img src="icons/ic-farmer-notes.svg" max-width="170px"></v-img>
          </v-col>
        </v-row>
      </div>
      <without-item-card
        v-else-if="shouldShowNotesNotFound"
        name="filter"
        text="WithoutItemCard.filter.not_found_text"
        title="WithoutItemCard.filter.not_found_title"
      />
      <modal-note
        v-if="noteRegisterOpen"
        :dialog="noteRegisterOpen"
        :current-note="selectedNote"
        @close="closeModalNote"
        @fetchNotes="fetchNotes"
      />
      <modal-filter-note
        :dialog="noteFilterOpen"
        @close="closeModalFilterNote"
        @filters="setFilters"
      />
      <action-confirmation-dialog
        :dialog="confirmationDialog"
        :text="$t('Note.delete_confirm')"
        :text-cancel="$t('cancelar')"
        :text-confirm="$t('confirmar')"
        :cancel="cancelDelete"
        :confirm="confirmDelete"
        color-cancel="#8f8f8f"
        color-confirm="#39af49"
      />
    </v-container>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndDown"
      fab
      fixed
      class="floating-button-filter"
      @click="openModalFilterNote"
    >
      <v-icon>mdi-filter</v-icon>
    </v-btn>
    <v-btn
      v-if="
        $vuetify.breakpoint.mdAndDown &&
        enabledFarm &&
        hasPermission($p.note.create)
      "
      fab
      fixed
      class="floating-button-register"
      @click="openModalNote"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-card>
</template>

<script>
import ActionConfirmationDialog from '@/components/Dialogs/ActionConfirmationDialog.vue'
import DfLoading from '@/lib/components/Loader'
import Gallery from '@/modules/notes/components/Gallery.vue'
import LayoutMixin from '@/components/LayoutMixin.vue'
import ModalNote from '@/modules/notes/components/Modals/ModalNote.vue'
import ModalFilterNote from '@/modules/notes/components/Modals/ModalFilterNote.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import WithoutItemCard from '@/components/Banners/WithoutItemCard.vue'
import api from '@/services/api'
import { mapActions, mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'
import {
  formatDayOfMonth,
  formatDayDayOfMonth,
  formatHour,
} from '@/utils/formatters'

const CategoriesIcon = {
  buy_and_sell: 'dollar-sign',
  climate: 'cloud-sun-rain',
  diseases: 'disease',
  fertilizing: 'heart',
  machines: 'tractor',
  nematodes: 'bacterium',
  others: 'file',
  pests: 'bug',
}

export default {
  name: 'NotesList',

  mixins: [PermissionMixin, LayoutMixin],

  components: {
    ActionConfirmationDialog,
    DfLoading,
    Gallery,
    ModalFilterNote,
    ModalNote,
    WithoutItemCard,
  },

  props: {
    filteredNotes: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      noteRegisterOpen: false,
      noteFilterOpen: false,
      selectedNote: null,
      notesFiltered: [],
      filtersReceived: [],
      confirmationDialog: false,
    }
  },

  watch: {
    filteredNotes() {
      this.notesFiltered = this.filteredNotes
    },
    filters() {
      this.filtersReceived = this.filters
    },
  },

  async mounted() {
    const notesParams = { farmId: this.currentFarmId }

    await Promise.allSettled([
      this.fetchNotes(notesParams),
      this.fetchNoteCategories(),
    ])
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId', 'enabledFarm']),
    ...mapGetters('notes', ['notes', 'loading']),
    hasNotes() {
      return !!this.filteredNotes.length
    },
    notesGroupedByDay() {
      const days = {}

      const notes = this.notesFiltered.length ? this.notesFiltered : this.notes

      notes.forEach((note) => {
        const date = note.created_at.formatDate()
        const day = this.$vuetify.breakpoint.xs
          ? formatDayOfMonth(note.created_at)
          : formatDayDayOfMonth(note.created_at)
        const time = formatHour(note.created_at)

        if (!days[date]) {
          days[date] = { day, notes: [] }
        }

        days[date].notes.push({ ...note, created_at: time })
      })

      return Object.values(days)
    },
    shouldShowFilterReset() {
      return this.filters.length && !this.loading
    },
    shouldShowNotes() {
      return !this.loading && this.hasNotes
    },
    shouldShowNoNotes() {
      return !this.filters.length && !this.loading && !this.hasNotes
    },
    shouldShowNotesNotFound() {
      return this.filters.length && !this.loading && !this.hasNotes
    },
  },

  methods: {
    ...mapActions('notes', ['fetchNotes', 'fetchNoteCategories']),
    openFile(file) {
      window.open(file.storage_link, '_blank')
    },
    docsFiles(files) {
      return files.filter((file) => {
        const extension = file.name.split('.').pop()
        return ['doc', 'docx', 'pdf', 'xls', 'xlsx'].includes(extension)
      })
    },
    imgFiles(files) {
      return files.filter((file) => {
        const extension = file.name.split('.').pop()
        return ['jpg', 'jpeg', 'png', 'heic'].includes(extension)
      })
    },
    setFilters(filters) {
      this.$emit('filters', filters)
    },
    iconCategory(category) {
      return CategoriesIcon[category]
    },
    openModalNote(note) {
      logEvent(events.notesModule.newNote)
      this.selectedNote = note?.id ? note : null
      this.noteRegisterOpen = true
    },
    closeModalNote() {
      this.noteRegisterOpen = false
    },
    openModalFilterNote() {
      this.noteFilterOpen = true
    },
    closeModalFilterNote() {
      this.noteFilterOpen = false
    },
    wasEdited(note) {
      return note?.edited_by?.name ?? false
    },
    hasPlot(note) {
      return note?.plot?.name ?? false
    },
    openModalDeleteNote(note) {
      this.confirmationDialog = true
      this.note = note
    },
    cancelDelete() {
      this.confirmationDialog = false
    },
    async confirmDelete() {
      try {
        await this.deleteNote(this.note.id)
        logEvent(events.notesModule.deletedNote)

        this.$root.$emit('notify', 'success', this.$t('Note.delete_success'))

        await this.fetchNotes({ farmId: this.currentFarmId })

        this.confirmationDialog = false
      } catch (error) {
        this.confirmationDialog = false
      }
    },
    async deleteNote(noteId) {
      await api.notes.delete(noteId)
    },
    async clearFilters() {
      await this.fetchNotes({ farmId: this.currentFarmId })
      this.notesFiltered = []
      this.filtersReceived = []
      this.$emit('clear-filters')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-icon {
  font-size: 1.1rem !important;
}
.v-btn--outlined {
  border-color: #aab2a9;
  border-width: 1px;
}
.filter-text {
  color: #1a2b46;
  font-size: 16px;
  font-family: Rubik;
  font-weight: 500;
  line-height: 18px;
}
.clear-filter-button {
  color: #1a2b46;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
  cursor: pointer;
}
.icon-clear-filter {
  color: #c3d1e9 !important;
  text-align: center;
  font-size: 12px;
  font-family: Font Awesome 5 Free;
  font-weight: 900;
  line-height: 9.5px;
  cursor: pointer;
}
.personalized-icon {
  color: #305082;
  font-size: 0.9rem;
}
.file-name {
  color: #5b6459;
  font-size: 12px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0;
  text-transform: uppercase;
}
.paperclip-icon {
  color: #aab2a9;
  font-size: 0.8rem;
}
.download-icon {
  color: #39af49;
  font-size: 0.8rem;
}
.text1 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 18px;
  text-transform: uppercase;
  color: #51594f;
}
.text2 {
  margin: 5px 0 6px 0;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 2.375rem;
  line-height: 40px;
  color: #1a2b46;
}
.text3 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #51594f;
}
.color-neutral-m-3 {
  color: #253521 !important;
}
.color-neutral-0 {
  color: #5b6459 !important;
}
.color-neutral-1 {
  color: #788476 !important;
}
.color-neutral-2 {
  color: #aab2a9 !important;
}
.color-secondary-light-1 {
  color: #305082 !important;
}
.note_day {
  font-family: 'Rubik' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
}
.note_hour {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}
.note_category {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}
.note_plot {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}
.note_title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
}
.note_author {
  font-size: 14px;
  font-weight: 400px;
  line-height: 18px;
  font-style: normal;
}
.note_description {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.card-style {
  border: 1px solid #e6e9e6;
  border-radius: 8px;
  padding: 20px;
}
.icon-actions {
  font-size: 15px !important;
  color: #788476 !important;
}
.button-new-note {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
}
.no-shadow {
  box-shadow: none;
}
.floating-button-filter {
  position: fixed;
  bottom: 150px;
  right: 12px;
  background-color: white !important;
  color: #39af49 !important;

  @include d(m) {
    bottom: 182px;
  }
}
.floating-button-register {
  position: fixed;
  bottom: 80px;
  right: 12px;
  color: white !important;
  background-color: #39af49 !important;
}
@media screen and (max-width: 425px) {
  .note_day {
    color: #253521 !important;
  }
}
</style>
