<template>
  <v-container class="pa-0" data-id="note_filter">
    <v-row v-if="!modalOpen" no-gutters>
      <v-col class="filter">
        {{ $t('filter') }}
      </v-col>
    </v-row>
    <v-row class="pt-5 pb-1" no-gutters>
      <v-col class="category">
        {{ $t('category') }}
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="pb-3 row-categories"
      v-for="category in notesCategories"
      :key="category.id"
    >
      <v-col>
        <v-checkbox
          v-model="filter"
          item-value="id"
          class="check-box-categories mt-n1"
          color="#39af49"
          :label="''"
          hide-details
          :value="`category_id:${category.id}`"
          :key="category.id"
        >
          <template #label>
            <v-row no-gutters>
              <font-awesome-icon
                class="personalized-icon"
                :icon="iconCategory(category.name)"
              />
              <span class="category-name">
                {{ categoryName(category.name) }}
              </span>
            </v-row>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row class="row-categories mb-5" no-gutters>
      <v-col>
        <span class="title-selectors">{{ $t('talhoes.plot') }}</span>
        <v-select
          v-model="plot"
          item-text="nome"
          item-value="id"
          background-color="white"
          color="#39af49"
          hide-details="auto"
          single-line
          outlined
          :items="plots"
          :placeholder="$t('select')"
          @change="setQueryFilter('plot_id:', $event)"
        />
      </v-col>
    </v-row>
    <v-row class="row-categories" no-gutters>
      <v-col>
        <span class="title-selectors">{{ $t('date') }}</span>
        <df-date-picker
          v-model="date"
          background-color="white"
          color="#39af49"
          outlined
          :placeholder="$t('select_date')"
          @change="setQueryFilter('created_at:', $event)"
        />
      </v-col>
    </v-row>
    <v-row class="row-categories" no-gutters>
      <v-col>
        <span class="title-selectors">{{ $t('user.users') }}</span>
        <v-select
          v-model="user"
          item-text="displayText"
          item-value="id"
          background-color="white"
          color="#39af49"
          hide-details="auto"
          single-line
          outlined
          :items="formattedUsers"
          :placeholder="$t('select')"
          @change="setQueryFilter('created_by:', $event)"
        />
      </v-col>
    </v-row>
    <v-row class="row-categories" v-if="!modalOpen">
      <v-col>
        <v-btn
          block
          class="white--text text-none button-filter"
          text
          @click="filterNotes()"
        >
          {{ $t('apply_filter') }}
          <v-icon class="icon-button" small>mdi-check</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DfDatePicker from '@/lib/Form/DatePicker.vue'
import { mapActions, mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

const CategoriesIcon = {
  buy_and_sell: 'dollar-sign',
  climate: 'cloud-sun-rain',
  diseases: 'disease',
  fertilizing: 'heart',
  machines: 'tractor',
  nematodes: 'bacterium',
  others: 'file',
  pests: 'bug',
}

export default {
  name: 'NotesFilter',

  components: {
    DfDatePicker,
  },

  props: {
    modalOpen: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      categoryNames: '',
      date: '',
      filter: [],
      plot: [],
      user: [],
    }
  },

  async created() {
    await Promise.allSettled([this.fetchPlots(), this.fetchUsers()])
  },

  watch: {
    filter() {
      this.$emit('toModal', this.filter)
    },
  },

  computed: {
    ...mapGetters('notes', ['notesCategories']),
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('plot', ['plots']),
    ...mapGetters('user', ['users']),
    formattedUsers() {
      return this.users.map((user) => {
        return {
          ...user,
          displayText: user.name || user.email,
        }
      })
    },
  },

  methods: {
    ...mapActions('notes', ['fetchNotes']),
    ...mapActions('plot', ['getPlots']),
    ...mapActions('user', ['fetchUsers']),
    categoryName(categoryName) {
      return this.$t(`Note.category.${categoryName}`)
    },
    async fetchPlots() {
      const params = {
        farmId: this.currentFarmId,
        params: 'created_at:desc',
      }
      await this.getPlots(params)
    },
    async filterNotes() {
      const params = {
        farmId: this.currentFarmId,
        filter: '?filter=' + this.filter.join(','),
      }
      await this.fetchNotes(params)
      this.sendFilterAnalytics(this.filter)
      this.$emit('filters', this.filter)
    },
    iconCategory(categoryName) {
      return CategoriesIcon[categoryName]
    },
    // accessed by refs
    // eslint-disable-next-line vue/no-unused-properties
    reset() {
      this.filter = []
      this.plot = null
      this.date = null
      this.user = null
    },
    sendFilterAnalytics(filters) {
      const filterNames = filters.map((filter) => {
        const [key, value] = filter.split(':')

        if (key === 'plot_id') {
          return (
            'plot_' + this.plots.find((plot) => plot.id === Number(value)).nome
          )
        }

        if (key === 'created_by') {
          return (
            'user_' + this.users.find((user) => user.id === Number(value)).name
          )
        }

        if (key === 'category_id') {
          return (
            'category_' +
            this.notesCategories.find(
              (category) => category.id === Number(value)
            ).name
          )
        }

        return value
      })

      this.categoryNames = filterNames.join('_')

      logEvent(
        events.notesModule.selectFilter({
          filter: this.categoryNames,
        })
      )
    },
    setQueryFilter(key, value) {
      this.filter = this.filter.filter((item) => !item.includes(key))
      this.filter = [...this.filter, `${key}${value}`]
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__append-inner {
  margin-top: 12px !important;
}
.button-filter {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0;
  background-color: #39af49 !important;
  border-color: #39af49;
}
.filter {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #101a2a;
}
.personalized-icon {
  color: #5b6459;
  margin-right: 2px;
}
.category-name {
  color: #0e180c;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  line-height: 18px;
  padding-left: 8px;
}
.category {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #1a2b46;
}
.check-box-categories {
  background: #e6e9e6;
  border-radius: 8px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 8px !important;
}
.title-selectors {
  color: #253521;
  font-size: 12px;
  font-family: Rubik;
  font-weight: 500;
  line-height: 18px;
}
.icon-button {
  padding-top: 2px;
  padding-left: 8px;
}
</style>
