import { DfTooltip } from '@/lib/components/Tooltip/DfTooltip.js'

class NoPermissionTooltip extends DfTooltip {
  static get observedAttributes() {
    return ['hide', 'position']
  }

  attributeChangedCallback(name, _, newValue) {
    if (name === 'hide') {
      this.hide = true
    }
    if (name === 'position') {
      this.position = newValue
    }
  }

  constructor() {
    super()
    this.text = 'Você não tem permissão'
  }
}

customElements.define('no-permission-tooltip', NoPermissionTooltip)
