<template>
  <div>
    <permission-failed-message v-if="!hasPermission($p.note.list)" />
    <div v-else>
      <header-notes
        :next-route-params="nextRouteParams"
        :previous-path="previousRoute.path"
      />
      <div class="home__content">
        <farm-disabled v-if="!farms.length" />
        <div v-else class="df-flex-l">
          <div class="df-col-3 content__filter">
            <notes-filter ref="filterNotes" @filters="setFilters" />
          </div>
          <div class="df-col-9 df-col-12-m df-col-12-t">
            <notes-list
              :filtered-notes="currentNotes"
              :filters="filters"
              @clear-filters="resetFilters"
              @filters="setFilters"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FarmDisabled from '@/components/Banners/FarmDisabled.vue'
import NotesFilter from '@/modules/notes/components/NotesFilter.vue'
import HeaderNotes from '@/modules/notes/components/Header.vue'
import NotesList from '@/modules/notes/components/NotesList.vue'
import PermissionMixin from '@/components/Permission/PermissionMixin.vue'
import { mapGetters } from 'vuex'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'NotesHome',

  mixins: [PermissionMixin],

  components: {
    FarmDisabled,
    NotesFilter,
    HeaderNotes,
    NotesList,
  },

  data() {
    return {
      filters: [],
      nextRouteParams: {},
      previousRoute: {},
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRoute = from
      vm.nextRouteParams = to
    })
  },

  mounted() {
    logEvent(events.notesModule.visitedPage)
  },

  computed: {
    ...mapGetters('farms', ['farms']),
    ...mapGetters('notes', ['filteredNotes', 'notes']),
    currentNotes() {
      if (this.filters.length) return this.filteredNotes
      return this.notes
    },
  },

  methods: {
    setFilters(filters) {
      this.filters = filters
    },
    resetFilters() {
      this.filters = []
      this.$refs.filterNotes && this.$refs.filterNotes.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
.home__content {
  max-width: 1472px;
  margin: 0px auto;
  padding: 0px 16px 40px 16px;

  @include d(m) {
    padding: 0px 8px 12px 8px;
  }
  .content__filter {
    @include d(t) {
      display: none;
    }
    @include d(m) {
      display: none;
    }
  }
}
</style>
