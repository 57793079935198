import borderRadius from '@/assets/styles/__border-radius.module.scss'
import colors from '@/assets/styles/__colors.module.scss'
import fonts from '@/assets/styles/__fonts.module.scss'
import spacers from '@/assets/styles/__spacers.module.scss'

class DfTooltip extends HTMLElement {
  static get observedAttributes() {
    return ['hide', 'position', 'text']
  }

  attributeChangedCallback(name, _, newValue) {
    if (name === 'hide') {
      this.hide = true
    }
    if (name === 'position') {
      this.position = newValue
    }
    if (name === 'text') {
      this.text = newValue
    }
  }

  set hide(value) {
    this._hide = value
    this._updateVisibility()
  }

  set position(value) {
    this._position = value
  }

  set text(value) {
    this._text = value
    this._updateText()
  }

  _updateText() {
    this.tooltipTextElement.textContent = this._text
  }

  _updateVisibility() {
    if (this._hide) {
      this.hideTooltip()
    } else {
      this.showTooltip()
    }
  }

  showTooltip() {
    if (!this._text || this._hide) return

    const rect = this.tooltipTarget.getBoundingClientRect()

    if (this._position === 'bottom') {
      this.tooltipTextElement.style.top = `${rect.bottom + window.scrollY}px`
      this.tooltipTextElement.style.left = `${
        rect.left + window.scrollX + rect.width / 2
      }px`
      this.tooltipTextElement.style.transform = 'translateX(-50%)'
    } else if (this._position === 'left') {
      this.tooltipTextElement.style.top = `${
        rect.top + window.scrollY + rect.height / 2
      }px`
      this.tooltipTextElement.style.left = `${rect.left + window.scrollX}px`
      this.tooltipTextElement.style.transform = 'translate(-100%, -50%)'
    } else if (this._position === 'right') {
      this.tooltipTextElement.style.top = `${
        rect.top + window.scrollY + rect.height / 2
      }px`
      this.tooltipTextElement.style.left = `${rect.right + window.scrollX}px`
      this.tooltipTextElement.style.transform = 'translateY(-50%)'
    } else {
      this.tooltipTextElement.style.top = `${rect.top + window.scrollY}px`
      this.tooltipTextElement.style.left = `${
        rect.left + window.scrollX + rect.width / 2
      }px`
      this.tooltipTextElement.style.transform = 'translate(-50%, -100%)'
    }

    this.tooltipTextElement.style.visibility = 'visible'
    this.tooltipTextElement.style.opacity = '1'
  }

  hideTooltip() {
    this.tooltipTextElement.style.visibility = 'hidden'
    this.tooltipTextElement.style.opacity = '0'
  }

  disconnectedCallback() {
    document.body.removeChild(this.tooltipTextElement)
  }

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })

    const { borderRadiusM } = borderRadius
    const { colorBackgroundBlack, colorTextWhite } = colors
    const {
      paragraphXSmallFontFamily,
      paragraphXSmallFontHeight,
      paragraphXSmallFontSize,
      paragraphXSmallFontStyle,
      paragraphXSmallFontWeight,
    } = fonts
    const { spacingXs, spacingSm } = spacers

    this.tooltipTextElement = document.createElement('span')
    this.tooltipTextElement.classList.add('tooltip-text')
    this.tooltipTextElement.style.position = 'absolute'
    this.tooltipTextElement.style.visibility = 'hidden'
    this.tooltipTextElement.style.opacity = '0'
    this.tooltipTextElement.style.zIndex = '9999'
    this.tooltipTextElement.style.transition = 'opacity 0.3s'
    this.tooltipTextElement.style.whiteSpace = 'nowrap'
    this.tooltipTextElement.style.fontFamily = paragraphXSmallFontFamily
    this.tooltipTextElement.style.fontHeight = paragraphXSmallFontHeight
    this.tooltipTextElement.style.fontSize = paragraphXSmallFontSize
    this.tooltipTextElement.style.fontStyle = paragraphXSmallFontStyle
    this.tooltipTextElement.style.fontWeight = paragraphXSmallFontWeight
    this.tooltipTextElement.style.background = colorBackgroundBlack
    this.tooltipTextElement.style.color = colorTextWhite
    this.tooltipTextElement.style.padding = `${spacingXs} ${spacingSm}`
    this.tooltipTextElement.style.borderRadius = borderRadiusM

    document.body.appendChild(this.tooltipTextElement)

    this.shadowRoot.innerHTML = `
    <style scoped>
      .df-tooltip {
        position: relative;
        display: inline-block;
      }
      </style>
      <span class="df-tooltip">
        <slot></slot>
      </span>
      `

    this.tooltipTarget = this.shadowRoot.querySelector('.df-tooltip')

    this.tooltipTarget.addEventListener('mouseenter', () => this.showTooltip())
    this.tooltipTarget.addEventListener('mouseleave', () => this.hideTooltip())
  }
}

export { DfTooltip }

customElements.define('df-tooltip', DfTooltip)
